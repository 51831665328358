import React, {  useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import { gsap } from 'gsap';
import Navbar from '../components/Navbar';

const HomePage = () => {
  

  useEffect(() => {
    // GSAP hover animation with neon glow effect
    const featureCards = document.querySelectorAll('.feature-card');

    featureCards.forEach((card) => {
      card.addEventListener('mouseenter', () => {
        gsap.to(card, {
          boxShadow: '0 0 15px #0ff, 0 0 30px #0ff, 0 0 45px #0ff, 0 0 60px #0ff',
          duration: 0.6,
          ease: 'power3.out',
        });
      });

      card.addEventListener('mouseleave', () => {
        gsap.to(card, {
          boxShadow: 'none',
          duration: 0.6,
          ease: 'power3.in',
        });
      });
    });
  }, []);


  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-slate-800 to-slate-900 text-white flex flex-col">
      {/* Header Section */}
      <Navbar />

      {/* Main Section */}
      <main className="flex-1 text-center mt-24 flex flex-col justify-center items-center">
        <h1 className="text-4xl lg:text-5xl font-extrabold mb-8 mt-40">
          <Typewriter
            options={{
              strings: ['We are building something amazing.', 'Get ready for the future of education.'],
              autoStart: true,
              loop: true,
              delay: 50,
              deleteSpeed: 25,
            }}
          />
        </h1>
        <p className="mt-4 text-lg opacity-80">
          Welcome to a platform where learning meets innovation. We're crafting a space to elevate your educational journey.
        </p>

        <button className="bg-slate-700 text-white mt-8 px-6 py-3 rounded-lg text-lg border border-slate-300 hover:bg-slate-600 transition-transform transform hover:scale-110 duration-300">
          LET'S GET STARTED
        </button>

        {/* Features Section */}
        <section className="mt-16 px-4 lg:px-8 text-center">
          <h2 className="text-2xl lg:text-3xl font-bold mb-6 mt-20">Features</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="feature-card bg-slate-900 p-6 rounded-lg shadow-lg transition-all duration-500">
              <div className="text-4xl mb-4">📚</div>
              <h3 className="text-xl lg:text-2xl font-bold mb-2">Interactive Study Notes</h3>
              <p className="text-lg">Engage with dynamic study notes that adapt to your learning style and provide interactive content.</p>
            </div>
            <div className="feature-card bg-slate-900 p-6 rounded-lg shadow-lg transition-all duration-500">
              <div className="text-4xl mb-4">🗂️</div>
              <h3 className="text-xl lg:text-2xl font-bold mb-2">Extensive PYQ Database</h3>
              <p className="text-lg">Access a comprehensive database of previous year questions to prepare effectively for exams.</p>
            </div>
            <div className="feature-card bg-slate-900 p-6 rounded-lg shadow-lg transition-all duration-500">
              <div className="text-4xl mb-4">🔗</div>
              <h3 className="text-xl lg:text-2xl font-bold mb-2">Networking Opportunities</h3>
              <p className="text-lg">Connect with peers, educators, and industry professionals through our networking tools.</p>
            </div>
            <div className="feature-card bg-slate-900 p-6 rounded-lg shadow-lg transition-all duration-500">
              <div className="text-4xl mb-4">📈</div>
              <h3 className="text-xl lg:text-2xl font-bold mb-2">Personalized Learning Paths</h3>
              <p className="text-lg">Receive tailored learning paths based on your progress and goals.</p>
            </div>
            <div className="feature-card bg-slate-900 p-6 rounded-lg shadow-lg transition-all duration-500">
              <div className="text-4xl mb-4">🤝</div>
              <h3 className="text-xl lg:text-2xl font-bold mb-2">Community Support and Engagement</h3>
              <p className="text-lg">Participate in discussions, join study groups, and get support from the community.</p>
            </div>
          </div>
        </section>
      </main>

      {/* Enhanced Footer Section */}
      <footer className="bg-slate-900 py-8 text-white mt-32">
        <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center px-4 lg:px-0">
          <div className="lg:flex-1 text-center lg:text-left">
            <h4 className="font-bold text-lg mb-2">About Us</h4>
            <p className="text-sm opacity-70">
              We are dedicated to providing innovative tools to enhance your educational experience and help you succeed.
            </p>
          </div>

          <div className="lg:flex-1 text-center mt-8 lg:mt-0">
            <h4 className="font-bold text-lg mb-2">Quick Links</h4>
            <ul className="text-sm opacity-70">
              <li><a href="/" className="hover:underline">Home</a></li>
              <li><a href="/features" className="hover:underline">Features</a></li>
              <li><a href="/contact" className="hover:underline">Contact</a></li>
            </ul>
          </div>

          <div className="lg:flex-1 text-center mt-8 lg:mt-0">
            <h4 className="font-bold text-lg mb-2">Newsletter</h4>
            <form className="flex flex-col sm:flex-row justify-center sm:space-x-2">
              <input 
                type="email" 
                placeholder="Your email address" 
                className="px-4 py-2 rounded-lg text-black focus:outline-none" 
              />
              <button 
                type="submit" 
                className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4 sm:mt-0 hover:bg-blue-600 transition"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
        <div className="text-center text-sm opacity-60 mt-8">© 2024 Your Company. All rights reserved.</div>
      </footer>
    </div>
  );
};

export default HomePage;
