import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import Navbar from '../components/Navbar';

const branches = [
  { name: 'Computer Science', icon: '💻' },
  { name: 'Mechanical', icon: '🔧' },
  { name: 'Electrical', icon: '⚡' },
  { name: 'Civil', icon: '🏗️' },
  { name: 'Electronics', icon: '📡' },
  { name: 'Information Technology', icon: '🖥️' },
  { name: 'Biotechnology', icon: '🧬' },
  { name: 'Chemical Engineering', icon: '⚗️' },
  { name: 'Aeronautical', icon: '✈️' }
];

const BranchPage = () => {
  useEffect(() => {
    // GSAP soft glow hover effect for branch cards
    const branchCards = document.querySelectorAll('.branch-card');

    branchCards.forEach((card) => {
      card.addEventListener('mouseenter', () => {
        gsap.to(card, {
          scale: 1.05, // Slightly smaller scale for a subtle effect
          boxShadow: '0 0 20px rgba(0, 255, 255, 0.5), 0 0 40px rgba(0, 255, 255, 0.3), 0 0 60px rgba(0, 255, 255, 0.2)',
          duration: 0.3, // Fast, but smooth transition
          ease: 'power2.out',
        });
      });

      card.addEventListener('mouseleave', () => {
        gsap.to(card, {
          scale: 1,
          boxShadow: 'none',
          duration: 0.3, // Ensure the same timing for consistent feel
          ease: 'power2.in',
        });
      });
    });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-slate-800 to-slate-900 text-white">
      <Navbar />
      <main className="pt-20 pb-10 px-4">
        <h1 className="text-3xl lg:text-4xl font-extrabold mb-8 text-center">Select Your Branch</h1>
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {branches.map((branch, index) => (
              <Link
                to={`/branches/${branch.name}/semesters`}
                key={index}
                className="branch-card bg-slate-900 hover:bg-white hover:text-black transition-all duration-300 ease-in-out p-8 rounded-lg shadow-lg text-center flex flex-col items-center justify-center"
              >
                <span className="text-6xl mb-4">{branch.icon}</span>
                <h2 className="text-xl font-bold">{branch.name}</h2>
              </Link>
            ))}
          </div>
        </div>
      </main>

      <footer className="bg-slate-900 py-8 text-white mt-32">
  <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center px-4 lg:px-0">
    <div className="lg:flex-1 text-center lg:text-left">
      <h4 className="font-bold text-lg mb-2">About Us</h4>
      <p className="text-sm opacity-70">
        We are dedicated to providing innovative tools to enhance your educational experience and help you succeed.
      </p>
    </div>

    <div className="lg:flex-1 text-center mt-8 lg:mt-0">
      <h4 className="font-bold text-lg mb-2">Quick Links</h4>
      <ul className="text-sm opacity-70">
        <li><Link to="/" className="hover:underline">Home</Link></li>
        <li><Link to="/features" className="hover:underline">Features</Link></li>
        <li><Link to="/contact" className="hover:underline">Contact</Link></li>
      </ul>
    </div>

    <div className="lg:flex-1 text-center mt-8 lg:mt-0">
      <h4 className="font-bold text-lg mb-2">Newsletter</h4>
      <form className="flex flex-col sm:flex-row justify-center sm:space-x-2">
        <input 
          type="email" 
          placeholder="Your email address" 
          className="px-4 py-2 rounded-lg text-black focus:outline-none" 
        />
        <button 
          type="submit" 
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4 sm:mt-0 hover:bg-blue-600 transition"
        >
          Subscribe
        </button>
      </form>
    </div>
  </div>
  <div className="text-center text-sm opacity-60 mt-8">© 2024 Your Company. All rights reserved.</div>
</footer>

    </div>
  );
};

export default BranchPage;
