import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Navbar from '../components/Navbar';

// Fallback image in case no custom image is provided for a semester
const fallbackImage = 'https://via.placeholder.com/150/000000/FFFFFF/?text=No+Image';

const SemesterPage = () => {
  const { branchName } = useParams();
  const [semesters, setSemesters] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSemesters = async () => {
      try {
        const semCollection = collection(db, `branches/${branchName}/semesters`);
        const semSnapshot = await getDocs(semCollection);
        const semList = semSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSemesters(semList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching semesters:', error);
        setLoading(false);
      }
    };

    fetchSemesters();
  }, [branchName]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-slate-800 to-slate-900 text-white">
      <Navbar />
      <main className="pt-20 pb-10 px-4">
        <h1 className="text-3xl lg:text-4xl font-extrabold mb-8 text-center">Select a Semester</h1>

        {loading ? (
          <p className="text-center text-xl">Loading semesters...</p>
        ) : semesters.length === 0 ? (
          <p className="text-center text-xl">No semesters found for {branchName}</p>
        ) : (
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {semesters.map((semester, index) => (
                <div
                  key={index}
                  className="semester-card bg-slate-900 p-6 rounded-lg shadow-lg flex flex-col items-center hover:bg-white hover:text-black transition duration-300 ease-in-out"
                >
                  <img 
                    src={semester.imageUrl || fallbackImage} 
                    alt={`Semester ${semester.name}`} 
                    className="rounded-md mb-4 w-full h-48 object-cover"
                  />
                  <h2 className="text-xl font-bold mb-2">{semester.name}</h2>
                  <Link
                    to={`/branches/${branchName}/semesters/${semester.name}/resources`}
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                  >
                    View PDFs
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </main>

      <footer className="bg-slate-900 py-8 text-white mt-32">
  <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center px-4 lg:px-0">
    <div className="lg:flex-1 text-center lg:text-left">
      <h4 className="font-bold text-lg mb-2">About Us</h4>
      <p className="text-sm opacity-70">
        We are dedicated to providing innovative tools to enhance your educational experience and help you succeed.
      </p>
    </div>

    <div className="lg:flex-1 text-center mt-8 lg:mt-0">
      <h4 className="font-bold text-lg mb-2">Quick Links</h4>
      <ul className="text-sm opacity-70">
        <li><Link to="/" className="hover:underline">Home</Link></li>
        <li><Link to="/features" className="hover:underline">Features</Link></li>
        <li><Link to="/contact" className="hover:underline">Contact</Link></li>
      </ul>
    </div>

    <div className="lg:flex-1 text-center mt-8 lg:mt-0">
      <h4 className="font-bold text-lg mb-2">Newsletter</h4>
      <form className="flex flex-col sm:flex-row justify-center sm:space-x-2">
        <input 
          type="email" 
          placeholder="Your email address" 
          className="px-4 py-2 rounded-lg text-black focus:outline-none" 
        />
        <button 
          type="submit" 
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4 sm:mt-0 hover:bg-blue-600 transition"
        >
          Subscribe
        </button>
      </form>
    </div>
  </div>
  <div className="text-center text-sm opacity-60 mt-8">© 2024 Your Company. All rights reserved.</div>
</footer>


    </div>
  );
};

export default SemesterPage;
