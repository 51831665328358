// src/components/Navbar.js

import React, { useState } from 'react';
import { FaBars, FaTimes, FaUser, FaSignOutAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { signOut } from 'firebase/auth';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleUserMenu = () => {
    setUserMenuOpen(!isUserMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login'); // Redirect to login page after logout
    } catch (err) {
      console.error('Logout error:', err);
    }
  };

  return (
    <header className="navbar fixed top-0 w-full bg-black bg-opacity-80 py-4 px-6 lg:px-8 shadow-lg z-10 flex items-center justify-between">
      <div className="flex items-center">
        <div className="bg-blue-500 rounded-full h-8 w-8 flex items-center justify-center text-white font-bold text-xl">
          V
        </div>
        <h1 className="text-2xl lg:text-3xl font-extrabold text-white ml-2">Vidyamitra</h1>
      </div>

      <nav className={`hidden lg:flex space-x-6 lg:space-x-12 ${isMobileMenuOpen ? 'block' : 'hidden'} lg:block`}>
        <Link to="/" className="nav-link hover:text-blue-400 transition-transform transform hover:scale-105">HOME</Link>
        <Link to="/branches" className="nav-link hover:text-blue-400 transition-transform transform hover:scale-105">STUDY NOTES</Link>
        <Link to="/pyqs" className="nav-link hover:text-blue-400 transition-transform transform hover:scale-105">PYQS</Link>
        <Link to="/community" className="nav-link hover:text-blue-400 transition-transform transform hover:scale-105">COMMUNITY</Link>
        <Link to="/about" className="nav-link hover:text-blue-400 transition-transform transform hover:scale-105">ABOUT US</Link>
      </nav>

      <div className="flex items-center space-x-4">
        <button className="lg:hidden text-white text-2xl" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>

        <div className="relative">
          <button className="flex items-center text-white text-2xl" onClick={toggleUserMenu}>
            <FaUser />
          </button>
          {isUserMenuOpen && (
            <div className="absolute right-0 mt-2 bg-white text-black p-4 rounded-lg shadow-lg">
              <p className="text-lg font-semibold">
                Welcome, {auth.currentUser?.displayName || 'User'}!
              </p>
              <button onClick={handleLogout} className="mt-2 flex items-center text-red-500 hover:text-red-700 transition-transform transform hover:scale-105">
                <FaSignOutAlt className="mr-2" /> Logout
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`lg:hidden fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 flex flex-col items-center justify-center ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
        <button className="text-white text-3xl mb-4" onClick={toggleMobileMenu}>
          <FaTimes />
        </button>
        <nav className="flex flex-col items-center space-y-4">
          <Link to="/" onClick={toggleMobileMenu} className="nav-link text-white hover:text-blue-400 transition-transform transform hover:scale-105">HOME</Link>
          <Link to="/branches" onClick={toggleMobileMenu} className="nav-link text-white hover:text-blue-400 transition-transform transform hover:scale-105">STUDY NOTES</Link>
          <Link to="/pyqs" onClick={toggleMobileMenu} className="nav-link text-white hover:text-blue-400 transition-transform transform hover:scale-105">PYQS</Link>
          <Link to="/community" onClick={toggleMobileMenu} className="nav-link text-white hover:text-blue-400 transition-transform transform hover:scale-105">COMMUNITY</Link>
          <Link to="/about" onClick={toggleMobileMenu} className="nav-link text-white hover:text-blue-400 transition-transform transform hover:scale-105">ABOUT US</Link>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
