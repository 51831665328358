import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import Navbar from '../components/Navbar';
import { FaGithub, FaLinkedin } from 'react-icons/fa'; // Import icons

const About = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('your_service_id', 'your_template_id', form.current, 'your_user_id')
      .then(
        (result) => {
          alert('Message sent successfully!');
        },
        (error) => {
          alert('Failed to send the message. Please try again.');
        }
      );
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-slate-800 to-slate-900 text-white">
      <Navbar />
      <main className="flex flex-col lg:flex-row items-center justify-between py-20 px-4 lg:px-20">
        {/* Left Section - About Shubham */}
        <section className="flex flex-col items-center text-center lg:text-left lg:items-start lg:w-2/3 mb-12 lg:mb-0">
          <h1 className="text-4xl lg:text-5xl font-extrabold mb-6">About Vidyamitra</h1>
          <p className="text-lg lg:text-xl max-w-3xl mb-12">
            Welcome to <span className="text-blue-500 font-bold">Vidyamitra</span>, an innovative platform designed to make learning more engaging and accessible...
          </p>

          <div className="bg-slate-900 p-8 rounded-lg shadow-lg max-w-4xl">
            <h2 className="text-3xl font-bold mb-4">Meet the Creator</h2>
            <p className="text-lg mb-6">
              Hi, I'm <span className="text-blue-500 font-bold">Shubham Panda</span>, a passionate developer...
            </p>
            
            <div className="flex justify-around space-x-6">
              <a 
                href="https://github.com/your-github" 
                className="bg-gradient-to-r from-blue-500 to-teal-400 text-white px-6 py-3 rounded-lg text-lg font-semibold flex items-center space-x-3 shadow-lg hover:shadow-xl transition transform hover:scale-105 duration-300"
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FaGithub className="w-6 h-6" /> <span>GitHub</span>
              </a>
              <a 
                href="https://linkedin.com/in/your-linkedin" 
                className="bg-gradient-to-r from-blue-500 to-teal-400 text-white px-6 py-3 rounded-lg text-lg font-semibold flex items-center space-x-3 shadow-lg hover:shadow-xl transition transform hover:scale-105 duration-300"
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FaLinkedin className="w-6 h-6" /> <span>LinkedIn</span>
              </a>
            </div>
          </div>
        </section>

        {/* Right Section - Message Form */}
        <section className="bg-slate-900 p-8 rounded-lg shadow-lg w-full lg:w-1/3">
          <h2 className="text-3xl font-bold mb-8 text-center">Send Me a Message</h2>
          <form ref={form} onSubmit={sendEmail} className="space-y-4">
            <div>
              <label className="block text-lg font-medium mb-2">Your Name</label>
              <input
                type="text"
                name="user_name"
                className="w-full p-3 rounded-md bg-slate-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-lg font-medium mb-2">Your Email</label>
              <input
                type="email"
                name="user_email"
                className="w-full p-3 rounded-md bg-slate-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-lg font-medium mb-2">Message</label>
              <textarea
                name="message"
                className="w-full p-3 rounded-md bg-slate-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-gradient-to-r from-blue-500 to-teal-400 text-white px-6 py-3 rounded-lg text-lg font-semibold w-full shadow-lg hover:shadow-xl transition transform hover:scale-105 duration-300"
            >
              Send Message
            </button>
          </form>
        </section>
      </main>

      <footer className="bg-slate-900 py-8 mt-20">
        <div className="container mx-auto text-center text-sm text-white opacity-70">
          © 2024 Vidyamitra. Built with ❤️ by Shubham Panda.
        </div>
      </footer>
    </div>
  );
};

export default About;
