// src/firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCPWmyisdkgu1QfLDWsvNdtvgxVqhpJHww',
  authDomain: 'vidyamitra23.firebaseapp.com',
  projectId: 'vidyamitra23',
  storageBucket: 'vidyamitra23.appspot.com',
  messagingSenderId: '380892168685',
  appId: '1:380892168685:web:e9d217addb7cd07dc2e7e4',
  measurementId: 'G-E613RSLWKG',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Initialize Firestore
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { db, analytics ,auth};
