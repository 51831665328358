import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import BranchPage from './pages/BranchPage';
import SemesterPage from './pages/SemesterPage';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgetPassword';
import About from './pages/AboutPage';
import ProtectedRoute from './components/ProtectedRoute'; // Add ProtectedRoute
import { AuthProvider } from './authContext'; // Authentication context for managing user state

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/about" element={<About />} />

          {/* Protected Routes */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/branches"
            element={
              <ProtectedRoute>
                <BranchPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/branches/:branchName/semesters"
            element={
              <ProtectedRoute>
                <SemesterPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
